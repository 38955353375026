import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import { MdViewModule, MdViewStream } from 'react-icons/md';
import SEO from '../components/seo';

const GalleryHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const GalleryStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  .portrait {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    position: relative;
    margin-bottom: 2rem;
    @media (min-width: 600px) {
      margin-bottom: 4rem;
    }
  }

  .caption {
    /* padding: 1rem 0 0; */
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 0.5rem;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    /* text-align: center; */
    color: var(--grey-4);

    p {
      margin: 0;
    }

    .portait & {
      p {
        max-width: 700px;
        margin: 0 auto;
      }
    }
  }
`;

const LayoutToggle = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  transition: var(--tran);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: var(--grey-3);
  }

  svg {
    width: 26px;
    height: 26px;
  }
`;

export default function Gallery({ data }) {
  const [isLayout, setLayout] = useState(true);

  const toggleLayout = () => setLayout((prevState) => !prevState);

  return (
    <>
      <SEO title={data.gallery.title} />
      <GalleryHeader>
        <h1 className="page-title">{data.gallery.title}</h1>
        <LayoutToggle type="button" onClick={toggleLayout}>
          {isLayout ? <MdViewStream /> : <MdViewModule />}
        </LayoutToggle>
      </GalleryHeader>

      {isLayout && (
        <Masonry
          breakpointCols={2}
          className="gallery-grid"
          columnClassName="gallery-grid_column"
        >
          {data.gallery.imagesGallery.map((image) => (
            <div key={image.asset.id}>
              <Img fluid={image.asset.fluid} alt={image.alt} />
              {/* <div className="caption">
                <p>{image.caption}</p>
              </div> */}
            </div>
          ))}
        </Masonry>
      )}
      {!isLayout && (
        <GalleryStyled>
          {data.gallery.imagesGallery.map((image) => {
            const ratio = image.asset.fluid.aspectRatio;
            const orientation = ratio > 1.3 ? 'landscape' : 'portrait';

            return (
              <li className={`${orientation}`} key={image.asset.id}>
                <Img fluid={image.asset.fluid} alt={image.alt} />
                <div className="caption">
                  <p>{image.caption}</p>
                </div>
              </li>
            );
          })}
        </GalleryStyled>
      )}
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    gallery: sanityGallery(slug: { current: { eq: $slug } }) {
      title
      id
      imagesGallery {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
            aspectRatio
          }
          id
        }
        caption
        alt
      }
    }
  }
`;
